export const environment = {
  production: false,
  adminRoot: '/app',
  dashboardUrl: 'http://54.179.65.227',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  defaultColor: 'light.blueyale',
  socketUrl: 'http://47.129.37.154:8000/',
  // apiUrl: 'http://47.129.37.154:8000/v3',
  // socketUrl: 'https://api.deliver.sg/',
   apiUrl: 'https://api.deliver.sg/v3',
   BASE_URL: 'https://api.deliver.sg/',
  imageUrl: 'https://deliversgbucket.s3.ap-southeast-1.amazonaws.com/',
  // apiUrl: 'https://edelivery.appemporio.net/v3',
  // imageUrl: 'https://edelivery.appemporio.net/',
  // socketUrl: 'http://localhost:8000/',
  // socketUrl: 'https://apiedeliverynew.appemporio.net/',
  // apiUrl: 'http://localhost:8000/v3',
  // imageUrl: 'http://192.168.0.160:8000/',
  // socketUrl: 'https://apiedeliverynew.appemporio.net',
  // apiUrl: 'https://apiedeliverynew.appemporio.net/v3',
  // imageUrl: 'https://apiedeliverynew.appemporio.net/',
  // imageUrl: 'http://localhost:8000/',
  fbAppId : '725636362212100',
  isAuthGuardActive: true,
  googleAppId : '117663095692-09l2r6oijqe3i1h1rv1r95degvvkhhsr.apps.googleusercontent.com',
  firebase: {
    apiKey: "AIzaSyBViKhoMt_4-rQ92byigbEanQEAmVJS1aA",
    authDomain: "deliversg-1570451296267.firebaseapp.com",
    databaseURL: "https://deliversg-1570451296267.firebaseio.com",
    projectId: "deliversg-1570451296267",
    storageBucket: "deliversg-1570451296267.appspot.com",
    messagingSenderId: "117663095692",
    appId: "1:117663095692:web:9533a4ca4cd6789be154a9",
    measurementId: "G-42KJX4G47W"
  }
};
